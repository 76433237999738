import React, { useContext } from "react";
import { FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const Map = ({ title }) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="md:w-[95%] mx-auto mt-[60px]">
        <h2 className="text-center py-2">We Cover:</h2>
      <Tabs>
          <TabList>
            {rpdata?.dbPrincipal?.location?.slice(1,6).map((item, index) => {
              return <Tab key={index}>{item.address}</Tab>;
            })}
          </TabList>
          {rpdata?.dbPrincipal?.location?.slice(1,6).map((item, index) => {
              return <TabPanel key={index}><iframe
              loading="lazy"
              title="Cover Locations"
              src={item.url}
              style={{ width: "100%", height: "400px" }}
            /></TabPanel>
            })}
        </Tabs>
      </div>
    </div>
  );
};

export default Map;